import axios from "axios";

const axiosInterceptor = axios.create({
  baseURL: "https://apinftdatabase.katanainu.com/",
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "X-Requested-With": "XMLHttpRequest",
  },
});

axiosInterceptor.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem("katana-admin-token");
    if (accessToken) {
      config.headers = { Authorization: `Bearer ${accessToken}` };
    }
    return config;
  },
  (error) => {
    Promise.reject(error.response || error.message);
  }
);

export default axiosInterceptor;
