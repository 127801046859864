import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-hot-toast";
import { Button, Stack, Box, Modal } from "@mui/material";
import { validateNft } from "../utils/helpers";
import axiosInterceptor from "../axios/axiosInterceptors";
import { AgGridReact } from "ag-grid-react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "fit-content",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};
function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}
const HeaderButtonsComponent = ({
  deleteData,
  publishData,
  addAction,
  exportData,
  setRowData,
  fetchNfts,
  logoutHandler,
}) => {
  const fileInputRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [importedData, setImportedData] = useState(null);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const userRole = localStorage.getItem("katana-nft-role");
  const [rows, setRow] = useState([]);

  const importData = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      try {
        const fileContent = await file.text();
        const parsedData = JSON.parse(fileContent);

        if (!validateNft(parsedData)) {
          return false;
        }
        let originalData = parsedData.map((item) => {
          delete item._id;
          return item;
        });
        setImportedData([...originalData]);
        let dataArray = parsedData.map((item) => {
          const subItemObject = {};
          item.attributes.forEach((subItem) => {
            subItemObject[subItem.trait_type] = subItem.value;
          });

          return { ...item, ...item.types_list[0], ...subItemObject };
        });

        setRowData((prev) => [...prev, ...dataArray]);
      } catch (error) {
        console.error("Error parsing JSON:", error);
      }
    }
  };

  const saveImportedJson = () => {
    setLoading(true);
    axiosInterceptor
      .post("/api/v1/nft/create", importedData)
      .then(() => {
        toast.success("Data Added Successfully");
        fetchNfts();
        setImportedData(null);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.data?.message || "Error");
      })
      .finally(() => setLoading(false));
  };

  const viewNftData = () => {
    axiosInterceptor.get('/api/v1/nft/collection-links')
    .then(function (response) {
      console.log(response.data.links)
      setRow(response.data.links)
    })
  };
  useEffect(() => {
    viewNftData()
  }, [])
  

  return (
    <>
      <div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
         <Stack sx={style}>
  <TableContainer component={Paper} sx={{ width: '100%', height: '100%' }}>
    <Table sx={{ minWidth: 650 }} aria-label="simple table">
      <TableHead>
        <TableRow>
          <TableCell>Collection Name</TableCell>
          <TableCell>Ipfs Link</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.map((row) => (
          <TableRow
            key={row.name}
            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
          >
            <TableCell component="th" scope="row">
              {row.name}
            </TableCell>
            <TableCell align="right">{row.link}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
</Stack>
        </Modal>
      </div>
      <Stack
        sx={{
          gap: "0.5em",
          marginBottom: "1em",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <input
          type="file"
          accept=".json"
          ref={fileInputRef}
          style={{ display: "none" }}
          onChange={(e) => handleFileChange(e)}
        />
        <Stack flexDirection={"row"} gap={"0.5em"}>
          <Button onClick={deleteData} variant="contained" color="error">
            Delete Nft
          </Button>
          <Button
            onClick={publishData}
            disabled={userRole !== "admin"}
            variant="contained"
            color="success"
          >
            Publish
          </Button>
        </Stack>
        <Stack flexDirection={"row"} gap={"0.5em"}>
          <Button onClick={handleOpen} variant="contained">
            View Published Data
          </Button>
          <Button onClick={addAction} variant="contained">
            Add Nft
          </Button>

          <Button onClick={exportData} variant="contained">
            Export
          </Button>
          {importedData ? (
            <Button
              onClick={saveImportedJson}
              disabled={loading}
              variant="contained"
              color="success"
            >
              {loading ? "Saving..." : "Save Imported Data"}
            </Button>
          ) : (
            <Button onClick={importData} variant="contained">
              Import
            </Button>
          )}
          <Button onClick={logoutHandler} variant="contained" color="info">
            Logout
          </Button>
        </Stack>
      </Stack>
    </>
  );
};

export default HeaderButtonsComponent;
