import React from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  TextField,
  Button,
  Container,
  Typography,
  Box,
  FormControl,
  FormHelperText,
} from "@mui/material";
import { toast } from "react-hot-toast";
import axiosInterceptor from "../axios/axiosInterceptors";

const validationSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email is required"),
  password: Yup.string().required("Password is required"),
});

const LoginPage = ({ setIsLoggedIn }) => {
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      axiosInterceptor
        .post("/api/v1/auth/login", {
          email: values.email,
          password: values.password,
        })
        .then((res) => {
          localStorage.setItem("katana-admin-token", res.data.token);
          localStorage.setItem("katana-nft-role", res.data.user.role);
          setIsLoggedIn(true);
          toast.success("Logged In");
        })
        .catch((err) =>
          toast.error(err?.response?.data?.data?.message || "Error")
        )
        .finally(() => formik.setSubmitting(false));
    },
  });

  return (
    <Container
      maxWidth="xs"
      sx={{ display: "flex", marginTop: "3em", justifyContent: "center" }}
    >
      <Box mt={5}>
        <Typography variant="h5" align="center" gutterBottom>
          Login
        </Typography>
        <form onSubmit={formik.handleSubmit}>
          <FormControl
            fullWidth
            variant="outlined"
            margin="normal"
            error={formik.touched.email && !!formik.errors.email}
          >
            <TextField
              id="email"
              name="email"
              label="Email"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.email && formik.errors.email && (
              <FormHelperText>{formik.errors.email}</FormHelperText>
            )}
          </FormControl>
          <FormControl
            fullWidth
            variant="outlined"
            margin="normal"
            error={formik.touched.password && !!formik.errors.password}
          >
            <TextField
              id="password"
              name="password"
              label="Password"
              type="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.password && formik.errors.password && (
              <FormHelperText>{formik.errors.password}</FormHelperText>
            )}
          </FormControl>
          <Button
            disabled={formik.isSubmitting}
            fullWidth
            variant="contained"
            color="primary"
            type="submit"
          >
            {formik.isSubmitting ? "Submitting..." : "Login"}
          </Button>
        </form>
      </Box>
    </Container>
  );
};

export default LoginPage;
