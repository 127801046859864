import React, { useEffect, useState } from "react";
import "./styles.scss";
import { toast } from "react-hot-toast";
import AddNftModal from "../modals/AddNftModal";
import ConfirmationModal from "../modals/DeleteModal";
import { IconButton, Stack } from "@mui/material";
import axiosInterceptor from "../axios/axiosInterceptors";
import { BsFillEyeFill, BsPencilSquare } from "react-icons/bs";
import { exportJSON } from "../utils/helpers";
import HeaderButtonsComponent from "./HeaderButtonsComponent";
import NftTableComponent from "./NftTableComponent";

const AgGridTable = ({ setIsLoggedIn, startLoading, stopLoading }) => {
  const [nftData, setNftData] = useState([]);
  const [rowData, setRowData] = useState([]);
  const [showDlg, setShowDlg] = useState(false);
  const [action, setAction] = useState("create");
  const [selectedData, setSelectedData] = useState({});

  const [selectedRows, setSelectedRows] = useState([]);
  const [showDeleteDlg, setShowDeleteDlg] = useState(false);
  const [attributesArray, setAttributesArray] = useState([]);
  const [attributesFields, setAttributesFields] = useState({});

  const userRole = localStorage.getItem("katana-nft-role");

  const ActionsCellRenderer = ({ data }) => {
    return (
      <>
        <IconButton onClick={() => handleView(data)}>
          <BsFillEyeFill size={20} />
        </IconButton>
        <IconButton onClick={() => handleEdit(data)}>
          <BsPencilSquare size={20} />
        </IconButton>
      </>
    );
  };

  const handleCheckbox = (data) => {
    let updated = { ...data, complete: !data.complete };
    startLoading();
    axiosInterceptor
      .patch(`/api/v1/nft/update/${data._id}`, updated)
      .then(() => {
        toast.success("Nft updated Successfully");
        fetchNfts();
      })
      .catch((err) => {
        stopLoading();
        toast.error(err?.response?.data?.data?.message || "Error");
      });
  };
  const CheckboxCellRenderer = ({ data }) => {
    return (
      <input
        type="checkbox"
        checked={data.complete}
        disabled={userRole !== "admin"}
        onChange={() => handleCheckbox(data)}
        style={{ height: "17px", width: "17px" }}
      />
    );
  };

  let initColumns = [
    {
      headerName: "Actions",
      field: "actions",
      editable: false,
      sortable: false,
      filter: false,
      floatingFilter: false,
      cellRenderer: ActionsCellRenderer,
    },
    {
      headerName: "Name",
      field: "name",
      checkboxSelection: true,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
    },
    {
      headerName: "Description",
      field: "description",
    },
    {
      headerName: "Image",
      field: "image",
    },
    {
      headerName: "Amount",
      field: "amount",
    },
    {
      headerName: "Tag",
      field: "tag",
    },
    {
      headerName: "Collection",
      field: "collection_name",
    },
    {
      headerName: "Types List",
      field: "types_list",
      children: [
        {
          headerName: "Asset ID",
          field: "AssetID",
        },
        {
          headerName: "Asset Unique Index",
          field: "AssetUniqueIndex",
        },
        {
          headerName: "Data Table ID",
          field: "DataTableID",
        },
        {
          headerName: "Event Data Table ID",
          field: "EventDataTableID",
        },
      ],
    },
    {
      headerName: "Attributes",
      children: [],
    },
    {
      headerName: "Completed",
      field: "complete",
      cellRenderer: CheckboxCellRenderer,
    },
  ];

  const [columnDefs, setColumnDefs] = useState([...initColumns]);

  const logoutHandler = () => {
    localStorage.removeItem("katana-admin-token");
    setIsLoggedIn(false);
  };

  const fetchNfts = () => {
    startLoading();
    axiosInterceptor
      .get("api/v1/nft")
      .then((response) => {
        setNftData(response.data);
      })
      .catch((err) => {
        if (err?.response?.data?.data?.message === "Token Not Valid") {
          logoutHandler();
        }
        toast.error(err?.response?.data?.data?.message || "Error");
      })
      .finally(() => stopLoading());
  };

  const publishData = () => {
    startLoading();
    axiosInterceptor
      .post("api/v1/nft/upload")
      .then((response) => {
        response.data.data.map((link) => {
          return toast.success(`${link}`, { autoClose: false });
        });
        toast.success(`Collections Published. Following are the IPFS links:`, {
          autoClose: false,
        });
        // toast.success(
        //   <div>
        //     <p>Collections Published. Following are the IPFS links:</p>
        //     {response.data}
        //   </div>,
        //   {
        //     autoClose: false, // Prevent auto-closing
        //   }
        // );
        fetchNfts();
      })
      .catch((err) => {
        if (err?.response?.data?.data?.message === "Token Not Valid") {
          logoutHandler();
        }
        toast.error(err?.response?.data?.data?.message || "Error");
      })
      .finally(() => stopLoading());
  };

  useEffect(() => {
    fetchNfts();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (nftData?.attributes?.length) {
      let attr = {};
      const attrArray = nftData.attributes.map((item) => {
        attr[item] = "";
        return { headerName: item, field: item };
      });
      setAttributesFields(attr);
      setAttributesArray(attrArray);

      setColumnDefs((prevColumnDefs) => {
        const updatedColumnDefs = [...prevColumnDefs];
        updatedColumnDefs[7].children = [...attrArray];
        return updatedColumnDefs;
      });
    } else {
      setAttributesFields({});
      setAttributesArray([]);
      setColumnDefs([...initColumns]);
    }

    if (nftData?.data?.nfts?.length) {
      let dataArray = nftData?.data?.nfts?.map((item) => {
        const subItemObject = {};
        item.attributes.forEach((subItem) => {
          subItemObject[subItem.trait_type] = subItem.value;
        });

        return { ...item, ...item.types_list[0], ...subItemObject };
      });

      setRowData([...dataArray]);
    } else {
      setRowData([]);
    }

    // eslint-disable-next-line
  }, [nftData, showDlg]);

  const handleEdit = (data) => {
    setAction("edit");
    setSelectedData(data);
    setShowDlg(true);
  };

  const handleView = (data) => {
    setAction("view");
    setSelectedData(data);
    setShowDlg(true);
  };

  const deleteData = () => {
    if (!selectedRows.length) {
      toast.error("No nft selected");
    } else if (selectedRows.length) {
      setShowDeleteDlg(true);
    }
  };

  return (
    <Stack marginTop={"1em"}>
      <HeaderButtonsComponent
        deleteData={deleteData}
        addAction={() => {
          setAction("create");
          setShowDlg(true);
        }}
        fetchNfts={fetchNfts}
        publishData={publishData}
        setRowData={setRowData}
        exportData={() => {
          exportJSON(nftData?.data?.nfts);
          toast.success("Data Exported Succfully");
        }}
        logoutHandler={logoutHandler}
      />

      <NftTableComponent
        rowData={rowData}
        columnDefs={columnDefs}
        setSelectedRows={setSelectedRows}
      />
      <AddNftModal
        open={showDlg}
        action={action}
        fetchNfts={fetchNfts}
        selectedData={selectedData}
        disabled={action === "view"}
        onClose={() => setShowDlg(false)}
        attributesArray={attributesArray}
        attributesFields={attributesFields}
        setAttributesArray={setAttributesArray}
      />
      <ConfirmationModal
        open={showDeleteDlg}
        fetchNfts={fetchNfts}
        selectedRows={selectedRows}
        setShowDeleteDlg={setShowDeleteDlg}
        onClose={() => setShowDeleteDlg(false)}
      />
    </Stack>
  );
};

export default AgGridTable;
