import React, { useLayoutEffect, useRef, useState } from "react";
import LoginPage from "./auth/Login";
import { Toaster } from "react-hot-toast";
import { Stack, Typography } from "@mui/material";
import AgGridTable from "./components/AgGridTable";
import LoadingBar from "react-top-loading-bar";

const App = () => {
  const ref = useRef(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useLayoutEffect(() => {
    const accessToken = localStorage.getItem("katana-admin-token");
    if (accessToken) {
      setIsLoggedIn(true);
    }
  }, []);

  const startLoading = () => {
    ref.current.continuousStart();
  };
  const stopLoading = () => {
    ref.current.complete();
  };

  return (
    <Stack margin={"1em"}>
      <LoadingBar height={3} color="#1976d2" ref={ref} shadow={true} />
      <Typography variant="h3" sx={{ alignSelf: "center" }}>
        Nft Data Table
      </Typography>
      {isLoggedIn ? (
        <AgGridTable
          setIsLoggedIn={setIsLoggedIn}
          startLoading={startLoading}
          stopLoading={stopLoading}
        />
      ) : (
        <LoginPage setIsLoggedIn={setIsLoggedIn} />
      )}
      <Toaster />
    </Stack>
  );
};

export default App;
