import React, { useMemo, useRef } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-balham.css";

const NftTableComponent = ({ rowData, columnDefs, setSelectedRows }) => {
  const gridApiRef = useRef(null);

  const defaultColDef = useMemo(() => {
    return {
      sortable: true,
      filter: true,
      floatingFilter: true,
      width: 150,
    };
  }, []);

  const onSelectionChanged = () => {
    if (gridApiRef.current) {
      const selectedNodes = gridApiRef.current.getSelectedNodes();
      const selectedIds = selectedNodes.map((node) => node.data);
      setSelectedRows([...selectedIds]);
    }
  };

  return (
    <div
      className="ag-theme-balham"
      style={{ height: 750, width: "auto", overflow: "auto" }}
    >
      <AgGridReact
        rowHeight={35}
        rowData={rowData}
        columnDefs={columnDefs}
        rowSelection={"multiple"}
        defaultColDef={defaultColDef}
        suppressRowClickSelection={true}
        onSelectionChanged={onSelectionChanged}
        onGridReady={(params) => (gridApiRef.current = params.api)}
        onCellValueChanged={(value) => console.log({ rowData, value })}
      />
    </div>
  );
};

export default NftTableComponent;
