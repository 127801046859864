import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import axiosInterceptor from "../axios/axiosInterceptors";
import { toast } from "react-hot-toast";

const ConfirmationModal = ({
  open,
  onClose,
  selectedRows,
  fetchNfts,
  setShowDeleteDlg,
}) => {
  const deleteNft = () => {
    let ids = selectedRows.map((item) => {
      return item._id;
    });
    axiosInterceptor
      .post("/api/v1/nft/delete-multiple", { ids })
      .then(() => {
        toast.success("Nft's Deleted Successfully");
        fetchNfts();
      })
      .catch(() => toast.error("Error"));
    setShowDeleteDlg(false);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Confirm Deletion</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to delete this data? This action cannot be
          undone.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="contained" color="info">
          Cancel
        </Button>
        <Button onClick={deleteNft} variant="contained" color="error" autoFocus>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationModal;
