import React, { useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import "./../style.css";
import {
  Box,
  Stack,
  Modal,
  Button,
  TextField,
  Typography,
  IconButton,
  Radio,
  RadioGroup,
  FormControlLabel,
  CircularProgress,
} from "@mui/material";
import axios from "axios";
import Card from "@mui/material/Card";
import { toast } from "react-hot-toast";
import { AiFillDelete } from "react-icons/ai";
import CloseIcon from "@mui/icons-material/Close";
import CardContent from "@mui/material/CardContent";
import { BsFillCheckCircleFill } from "react-icons/bs";
import axiosInterceptor from "../axios/axiosInterceptors";
import { tagOptions } from "../utils/helpers";

const apiKey = "7f1c8ed854348d378814"; // <---------- your Pinata Project ID
const apiSecret =
  "bc70db1ecb9c3948540dbc3887c4aad3c426b98c11e803ecf41014d07f849f40"; // <---------- your Pinata Secret

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  image: Yup.string().required("Image link is required"),
  collection_name: Yup.string().required("Collection is required")
});

const AddNftModal = ({
  disabled,
  action,
  selectedData,
  open,
  onClose,
  fetchNfts,
  attributesFields,
  attributesArray,
  setAttributesArray,
}) => {
  const btnRef = useRef(null);
  const [fields, setFields] = useState([]);
  const [img, setImg] = useState(null);
  const [imgFile, setFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);

  const handleLoading = () => {
    setFile(null);
    setImageLoaded(false);
  };

  const imgUp = (e) => {
    setImg(e.target.files[0]);
    setFile(URL.createObjectURL(e.target.files[0]));
    formik.setFieldValue("image", e.target.files[0]);
  };

  // Uploading to IPFS
  const uploadToIPFS = async () => {
    if (img) {
      try {
        const formData = new FormData();
        formData.append("file", img);

        const resFile = await axios({
          method: "post",
          url: "https://api.pinata.cloud/pinning/pinFileToIPFS",
          data: formData,
          headers: {
            pinata_api_key: `${apiKey}`,
            pinata_secret_api_key: `${apiSecret}`,
            "Content-Type": "multipart/form-data",
          },
        });

        const ImgHash = `https://ipfs.io/ipfs/${resFile.data.IpfsHash}`;
        return ImgHash;
      } catch (error) {
        console.log("ipfs image upload error: ", error);
      }
    } else {
      return selectedData.image;
    }
  };

  useEffect(() => {
    formik.resetForm();
    setFields([]);
    setImg(null);
    setFile(null);
    if (open && action !== "create" && selectedData?.image) {
      setFile(selectedData.image);
      console.log(selectedData.image)
    }
    // eslint-disable-next-line
  }, [open]);

  const handleNft = async (values) => {
    setIsLoading(true);
    let imageHash = await uploadToIPFS();
    let nft = {
      name: values.name,
      image: imageHash,
      tag: values.tag,
      amount: values.amount,
      collection_name: values.collection_name,
      description: values.description,
      complete: values.complete,
      types_list: [
        {
          AssetID: values.AssetID,
          DataTableID: values.DataTableID,
          AssetUniqueIndex: values.AssetUniqueIndex,
          EventDataTableID: values.EventDataTableID,
        },
      ],
    };

    let attributeData = attributesArray.map((item) => {
      return {
        trait_type: item.headerName,
        value: values[item.headerName] ? values[item.headerName] : "",
      };
    });

    let nftData = { ...nft, attributes: [...attributeData] };
    if (action === "edit") {
      axiosInterceptor
        .patch(`/api/v1/nft/update/${selectedData._id}`, nftData)
        .then(() => {
          toast.success("Nft updated Successfully");
          onClose();
          handleLoading();
          fetchNfts();
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          toast.error(err?.response?.data?.data?.message || "Error");
        });
    } else {
      axiosInterceptor
        .post("/api/v1/nft/create", nftData)
        .then(() => {
          toast.success("Nft created Successfully");
          fetchNfts();
          onClose();
          handleLoading();
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          toast.error(err?.response?.data?.data?.message || "Error");
        });
    }
  };

  const handleAddField = () => {
    setFields([...fields, { key: "", value: "" }]);
    setTimeout(() => {
      if (btnRef.current) {
        btnRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
      }
    }, 0);
  };

  const handleDeleteField = (index) => {
    const updatedFields = [...fields];
    updatedFields.splice(index, 1);
    setFields(updatedFields);
  };

  const handleChange = (index, field, e) => {
    const updatedFields = [...fields];
    updatedFields[index][field] = e.target.value;
    setFields(updatedFields);
  };

  const handleConfirmAttribute = (attr, index) => {
    if (attr.key !== "" && attr.value !== "") {
      formik.setFieldValue(attr.key, attr.value);
      setAttributesArray([
        ...attributesArray,
        { headerName: attr.key, field: attr.key },
      ]);
      handleDeleteField(index);
    } else {
      toast.error("Add Key Name And Value");
    }
  };

  const handleDeleteAttribute = (index) => {
    const updatedFields = [...attributesArray];
    updatedFields.splice(index, 1);
    setAttributesArray(updatedFields);
  };

  const initialValues = {
    name: "",
    image: "",
    amount: "",
    collection_name:"",
    tag: "Game",
    AssetID: "",
    complete: false,
    DataTableID: "",
    description: "",
    EventDataTableID: "",
    AssetUniqueIndex: "",
    ...attributesFields,
  };

  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: validationSchema,
    initialValues:
      action === "edit" || action === "view" ? selectedData : initialValues,
    onSubmit: (values) => handleNft(values),
  });


  return (
    <Modal
      open={open}
      onClose={() => {
        handleLoading();
        onClose();
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "white",
          padding: "2rem",
          borderRadius: "20px",
          boxShadow: 24,
          maxWidth: 650,
          width: "100%",
          background: "#E6E6FA",
        }}
      >
        <Stack>
          <Typography alignSelf={"center"} variant="h5" fontWeight={"bold"}>
            {action === "create"
              ? "ADD METADATA"
              : action === "edit"
              ? "EDIT METADATA"
              : "VIEW METADATA"}
          </Typography>
        </Stack>
        <IconButton
          sx={{
            position: "absolute",
            top: "8px",
            right: "8px",
          }}
          onClick={() => {
            handleLoading();
            onClose();
          }}
        >
          <CloseIcon />
        </IconButton>

        <Stack style={{ marginTop: "10px" }}>
          <Stack
            sx={{
              overflow: "auto",
              height: "70vh",
              paddingTop: "10px",
              "&::-webkit-scrollbar": {
                width: "4px",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#e0e0e0",
                borderRadius: "4px",
              },
              paddingRight: "3px",
            }}
          >
            <Typography sx={{ fontWeight: "bold", marginTop: "0.5em" }}>
              Upload Image<span style={{ color: "red" }}>*</span>
            </Typography>
            <Stack className="upload-btn-wrapper">
              <button className="btn-file">
                {imgFile ? (
                  <>
                    {!imageLoaded && (
                      <CircularProgress color="success" size={20} />
                    )}
                    <img
                      style={{
                        width: "50%",
                        display: imageLoaded ? "" : "none",
                      }}
                      alt=""
                      src={imgFile}
                      onLoad={()=> setImageLoaded(true)}
                    />
                  </>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    id="image"
                  >
                    <path
                      fill="#464646"
                      d="M19 2H5a3 3 0 0 0-3 3v14a3 3 0 0 0 3 3h14a3 3 0 0 0 3-3V5a3 3 0 0 0-3-3Zm-9 5a2 2 0 1 1-2 2 2 2 0 0 1 2-2ZM7.49 20H5a1 1 0 0 1-.94-.71l3.09-3.71a1.08 1.08 0 0 1 .79-.36 1 1 0 0 1 .78.4l1 1.33ZM20 19a1 1 0 0 1-1 1h-9l5.47-7.52a1 1 0 0 1 .73-.41 1 1 0 0 1 .79.29L20 15.41Z"
                    />
                  </svg>
                )}
              </button>
              <input
                type="file"
                name="myfile"
                onChange={imgUp}
                disabled={action === "view"}
              />
            </Stack>
            {formik.touched.image && formik.errors.image && (
              <Typography color="error">{formik.errors.image}</Typography>
            )}

            <Typography sx={{ fontWeight: "bold", marginTop: "0.5em" }}>
              Name<span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              disabled={disabled}
              sx={{ marginTop: "8px", background: "white" }}
              inputProps={{ style: { height: "0.8em" } }}
              name="name"
              placeholder="Name"
              fullWidth
              margin="normal"
              value={formik.values.name}
              onChange={formik.handleChange}
            />
            {formik.touched.name && formik.errors.name && (
              <Typography color="error">{formik.errors.name}</Typography>
            )}
            <Typography sx={{ fontWeight: "bold", marginTop: "0.5em" }}>
              Description
            </Typography>
            <TextField
              disabled={disabled}
              sx={{ marginTop: "8px", background: "white" }}
              inputProps={{ style: { height: "0.8em" } }}
              name="description"
              placeholder="Description"
              fullWidth
              margin="normal"
              value={formik.values.description}
              onChange={formik.handleChange}
            />
            <Typography sx={{ fontWeight: "bold", marginTop: "0.5em" }}>
              Collection<span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              disabled={disabled}
              sx={{ marginTop: "8px", background: "white" }}
              inputProps={{ style: { height: "0.8em" } }}
              name="collection_name"
              placeholder="Collection"
              fullWidth
              margin="normal"
              value={formik.values.collection_name}
              onChange={formik.handleChange}
            />
            {formik.touched.collection_name && formik.errors.collection_name && (
              <Typography color="error">{formik.errors.collection_name}</Typography>
            )}
            <Typography sx={{ fontWeight: "bold", marginTop: "0.5em" }}>
              Amount
            </Typography>
            <TextField
              disabled={disabled}
              sx={{ marginTop: "8px", background: "white" }}
              inputProps={{ style: { height: "0.8em" } }}
              name="amount"
              placeholder="Amount"
              fullWidth
              margin="normal"
              value={formik.values.amount}
              onChange={formik.handleChange}
            />
            <Typography sx={{ fontWeight: "bold", marginTop: "0.5em" }}>
              Tag
            </Typography>
            <RadioGroup
              row
              name={"tag"}
              value={formik.values.tag}
              onChange={(e) => formik.setFieldValue("tag", e.target.value)}
            >
              {tagOptions.map((option) => (
                <FormControlLabel
                  disabled={disabled}
                  key={option.value}
                  value={option.value}
                  control={<Radio />}
                  label={option.label}
                />
              ))}
            </RadioGroup>

            <Typography
              sx={{
                fontSize: "1em",
                fontWeight: "700",
                marginTop: "1em",
                marginBottom: "0.2em",
              }}
            >
              TYPES LIST
            </Typography>

            <Stack sx={{ margin: "0.1em", padding: "5px" }}>
              <Card
                variant="elevation"
                sx={{
                  borderRadius: "16px",
                  borderTop: "1px solid rgba(0,0,0,0.12)",
                  boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
                }}
              >
                <CardContent>
                  <Typography sx={{ fontWeight: "bold", marginTop: "0.5em" }}>
                    Asset ID
                  </Typography>

                  <TextField
                    disabled={disabled}
                    sx={{ marginTop: "8px", background: "white" }}
                    inputProps={{ style: { height: "0.8em" } }}
                    name="AssetID"
                    placeholder="Asset ID"
                    fullWidth
                    margin="normal"
                    value={formik.values.AssetID}
                    onChange={formik.handleChange}
                  />
                  <Typography sx={{ fontWeight: "bold", marginTop: "0.5em" }}>
                    Asset Unique Index
                  </Typography>

                  <TextField
                    disabled={disabled}
                    sx={{ marginTop: "8px", background: "white" }}
                    inputProps={{ style: { height: "0.8em" } }}
                    name="AssetUniqueIndex"
                    placeholder="Asset Unique Index"
                    fullWidth
                    margin="normal"
                    value={formik.values.AssetUniqueIndex}
                    onChange={formik.handleChange}
                  />
                  <Typography sx={{ fontWeight: "bold", marginTop: "0.5em" }}>
                    Data Table ID
                  </Typography>

                  <TextField
                    disabled={disabled}
                    sx={{ marginTop: "8px", background: "white" }}
                    inputProps={{ style: { height: "0.8em" } }}
                    name="DataTableID"
                    placeholder="Data Table ID"
                    fullWidth
                    margin="normal"
                    value={formik.values.DataTableID}
                    onChange={formik.handleChange}
                  />
                  <Typography sx={{ fontWeight: "bold", marginTop: "0.5em" }}>
                    Event Data Table ID
                  </Typography>

                  <TextField
                    disabled={disabled}
                    sx={{ marginTop: "8px", background: "white" }}
                    inputProps={{ style: { height: "0.8em" } }}
                    name="EventDataTableID"
                    placeholder="Event Data Table ID"
                    fullWidth
                    margin="normal"
                    value={formik.values.EventDataTableID}
                    onChange={formik.handleChange}
                  />
                </CardContent>
              </Card>
            </Stack>

            {attributesArray?.length ? (
              <Stack
                sx={{
                  marginTop: "1em",
                  alignItems: "center",
                  flexDirection: "row",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "1em",
                    marginTop: "0.2em",
                    fontWeight: "700",
                    marginBottom: "0.2em",
                  }}
                >
                  ATTRIBUTES
                </Typography>
              </Stack>
            ) : (
              ""
            )}
            {attributesArray?.length ? (
              <Stack sx={{ margin: "0.1em", padding: "5px" }}>
                <Card
                  variant="elevation"
                  sx={{
                    borderRadius: "16px",
                    borderTop: "1px solid rgba(0,0,0,0.12)",
                    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  <CardContent>
                    {attributesArray?.map((item, i) => {
                      return (
                        <React.Fragment key={i}>
                          <Typography
                            sx={{ fontWeight: "600", marginTop: "0.5em" }}
                          >
                            {item.headerName}
                          </Typography>
                          <Stack
                            sx={{
                              gap: "1em",
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                          >
                            <TextField
                              disabled={disabled}
                              sx={{ marginTop: "8px", background: "white" }}
                              inputProps={{ style: { height: "0.8em" } }}
                              fullWidth
                              margin="normal"
                              name={item.headerName}
                              placeholder={item.headerName}
                              value={formik.values[item.headerName]}
                              onChange={(e) => {
                                formik.setFieldValue(
                                  item.headerName,
                                  e.target.value
                                );
                              }}
                            />
                            {!disabled && (
                              <Button
                                color="error"
                                variant="contained"
                                onClick={() => handleDeleteAttribute(i)}
                                sx={{
                                  height: "30%",
                                  width: "20%",
                                  textTransform: "capitalize",
                                }}
                              >
                                Delete
                              </Button>
                            )}
                          </Stack>
                        </React.Fragment>
                      );
                    })}
                  </CardContent>
                </Card>
              </Stack>
            ) : (
              ""
            )}
            <Stack marginTop={"3em"}>
              {fields.length
                ? fields.map((field, index) => (
                    <Stack
                      key={index}
                      sx={{
                        flexDirection: "row",
                        marginBottom: "1em",
                      }}
                    >
                      <TextField
                        fullWidth
                        label="Add New Attribute Key"
                        variant="outlined"
                        value={field.key}
                        onChange={(e) => handleChange(index, "key", e)}
                      />
                      <TextField
                        fullWidth
                        sx={{ marginLeft: "1em" }}
                        label="Add New Attribute Value"
                        variant="outlined"
                        value={field.value}
                        onChange={(e) => handleChange(index, "value", e)}
                      />
                      <Stack sx={{ flexDirection: "row" }}>
                        <IconButton
                          onClick={() => handleConfirmAttribute(field, index)}
                        >
                          <BsFillCheckCircleFill color={"green"} />
                        </IconButton>
                        <IconButton onClick={() => handleDeleteField(index)}>
                          <AiFillDelete color={"red"} />
                        </IconButton>
                      </Stack>
                    </Stack>
                  ))
                : ""}
              {!disabled && (
                <Button
                  ref={btnRef}
                  fullWidth
                  onClick={handleAddField}
                  color="primary"
                  variant="contained"
                  sx={{
                    width: "20%",
                    textTransform: "capitalize",
                  }}
                >
                  Add Attribute
                </Button>
              )}
            </Stack>
          </Stack>

          <Stack>
            {!disabled && (
              <Button
                fullWidth
                color="primary"
                variant="contained"
                onClick={formik.handleSubmit}
                disabled={isLoading}
                sx={{
                  width: "20%",
                  marginTop: "1em",
                  alignSelf: "center",
                }}
              >
                {isLoading ? "Submitting..." : "Submit"}
              </Button>
            )}
          </Stack>
        </Stack>
      </Box>
    </Modal>
  );
};

export default AddNftModal;
