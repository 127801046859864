import { toast } from "react-hot-toast";

export const exportJSON = (data) => {
  const jsonData = JSON.stringify(data, null, 2);
  const blob = new Blob([jsonData], { type: "application/json" });
  const url = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = "export.json";
  a.click();
  URL.revokeObjectURL(url);
};

export const validateTypesList = (typesListObj) => {
  const expectedProperties = [
    "DataTableID",
    "EventDataTableID",
    "AssetID",
    "AssetUniqueIndex",
  ];

  for (const prop of expectedProperties) {
    if (typeof typesListObj[prop] !== "string") {
      return false;
    }
  }

  return true;
};

export const validateNft = (nftData) => {
  if (!Array.isArray(nftData)) {
    toast.error("File structure is not correct");
    return false;
  }
  for (const obj of nftData) {
    if (
      typeof obj.name !== "string" ||
      typeof obj.amount !== "number" ||
      typeof obj.image !== "string" ||
      typeof obj.description !== "string" ||
      !Array.isArray(obj.attributes) ||
      !Array.isArray(obj.types_list) ||
      obj.types_list.length !== 1 ||
      !validateTypesList(obj.types_list[0])
    ) {
      toast.error("File structure is not correct");
      return false;
    } else {
      return true;
    }
  }
};

export const tagOptions = [
  { value: "Game", label: "Game" },
  { value: "Marketplace", label: "Market Place" },
];
